<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>
  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.name_' + MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{
          this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list.video.name_' + MY_CONSTANT.sqlWhereLang)
        }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" id="register_form">

          <el-form-item :label="this.$MyConstant('Lang.Video_cat_id')+'：'" prop="cat_id">
            <el-select style="width: 400px;" v-if="this.$MyConstant('sqlWhereLang')=='cn'" size="large" class="select400" v-model="ruleForm.cat_id" :placeholder="this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.Video_cat_id')" >
              <el-option label="大会主论坛"  value="100"></el-option>
              <el-option label="平行论坛"   value="101"> </el-option>
            </el-select>
            <el-select style="width: 400px;" v-if="this.$MyConstant('sqlWhereLang')=='en'" size="large"  class="select400" v-model="ruleForm.cat_id" :placeholder="this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.Video_cat_id')" >
              <el-option label="Main Forum"  value="100"></el-option>
              <el-option label="Parallel forum"   value="101"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="this.$MyConstant('Lang.CVideo_title')+'：'" prop="title">
            <!--                        '[^\\u4e00-\\u9fa5]': '',-->
            <el-input class="input400" @keyup.native="strReplace('title',{' ': ''})" v-model="ruleForm.title"
                      :placeholder="this.$MyConstant('Lang.exampleCVideo_title')" onkeyup=""></el-input>
          </el-form-item>
          <el-form-item :label="MY_CONSTANT.Lang.EVideo_title+'：'" prop="title_en">
            <el-input class="input400" v-model="ruleForm.title_en"
                      @keyup.native="strReplace('title_en',{'[\\u4E00-\\u9FA5]': ''})"
                      :placeholder="this.$MyConstant('Lang.exampleEVideo_title')"></el-input>
          </el-form-item>


          <el-form-item :label="MY_CONSTANT.Lang.VideoImg+'：'" prop="img">
            <el-button size="large" @click="uploadPicture"><i class="el-icon-upload2"></i>&nbsp;&nbsp;<span
                class="uploadText">{{ MY_CONSTANT.Lang.uploadFile }}</span></el-button>
            <div style="color:#999" class=" img">
              {{ this.$MyConstant('Lang.LogoUploadDesc') + this.$MyConstant('Upload.video.coverImgSize') + 'M,' + this.$MyConstant('Upload.video.coverImgWidth') + '*' + this.$MyConstant('Upload.video.coverImgHeight') }}
            </div>
            <img style="width: 200px" v-show="ruleForm.img" :src="ruleForm.img" class="avatar">
          </el-form-item>


          <el-form-item :label="this.$MyConstant('Lang.Video_url')+'：'" prop="url" class="Linheight28">
            <el-upload
                :file-list="ruleForm.url"
                class="upload-demo"
                :accept="MY_CONSTANT.Upload.video.videoType"
                :action="MY_CONSTANT.Api_CONSTANT.baseUrl+'/api/upload/a'"
                :headers="MY_CONSTANT.Api_CONSTANT.baseApiHeaders"
                :before-upload="beforeUpload"
                :on-success="handleSuccess"
                :on-exceed="handleExceed"
                multiple
                :limit="5"
                :on-remove="handleRemove"
            >
              <el-button size="large"><i class="el-icon-upload2"></i>&nbsp;&nbsp;<span
                  class="uploadText">{{ MY_CONSTANT.Lang.uploadFile }}</span></el-button>
              <template #tip>
                <div class="el-upload__tip">
                  {{ MY_CONSTANT.Lang.Video_tip }}{{ MY_CONSTANT.Upload.video.videoSize }}M
                </div>
              </template>
            </el-upload>
          </el-form-item>

          <el-form-item :label="'\n'">
            <el-button  type="danger"
                       @click="submitForm('ruleForm')">{{ MY_CONSTANT.Lang.submit }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list')">
          <custom-link :style="{'color': 'video' == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_' + MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>
        <Components :datas='{
        "Template/About-right": "",
         }'>
        </Components>
      </div>
    </div>

  </div>

  <!-- 剪裁组件弹窗 -->
  <el-dialog
      :title="MY_CONSTANT.Lang.Upload+MY_CONSTANT.Lang.VideoImg"
      v-model="cropperModel"
      width="100%"
      style="max-width: 1000px;"
      center
  >

    <upload-cropper
        @uploadImgSuccess="handleUploadSuccess"
        :cropWidth="MY_CONSTANT.Upload.video.coverImgWidth"
        :cropHeight="MY_CONSTANT.Upload.video.coverImgHeight"
        ref="child">
    </upload-cropper>
  </el-dialog>
</template>

<script>
import Components from '../../../modules/Components.vue'
import validate, {validateWorldMobile, validateUser, validateSelect} from "@/assets/js/validation";
import api from "@/assets/js/api";
import uploadCropper from "./VueCropper";
import {decryptString} from "@/assets/js/globalFunctions";

export default {
  components: {
    Components,
    uploadCropper
  },
  data() {
    return {
      cropperModel: false,
      ruleForm: {
        cat_id:'100',
        title: '',
        title_en: '',
        img: '',
        url: []
      },
      rules: {
      cat_id: [

        { required: true, message: () => this.$MyConstant('Lang.PleaseSelect')+this.$MyConstant('Lang.Video_cat_id'), trigger: ['change','blur'] }

      ],
        title: [
          {
            required: this.$MyConstant('sqlWhereLang') == 'cn',
            message: () => this.$MyConstant('Lang.PleaseEnter') + this.$MyConstant('Lang.CVideo_title'),
            trigger: 'blur'
          },
        ],
        title_en: [
          {
            required: true,
            message: () => this.$MyConstant('Lang.PleaseEnter') + this.$MyConstant('Lang.EVideo_title'),
            trigger: 'blur'
          },
        ],
        img: [
          {
            required: true,
            message: () => this.$MyConstant('Lang.uploadFile') + this.$MyConstant('Lang.VideoImg'),
            trigger: 'blur'
          },
        ],
        url: [
          {required: true, message: () => this.$MyConstant('Lang.uploadFile') + this.$MyConstant('Lang.Video_url'), trigger: 'blur'},
        ],

      }
    }
  },
  watch: {
    'MY_CONSTANT.sqlWhereLang': {
      immediate: true, // 初始化时立即执行
      async handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.jiazaiArr();
      }
    }
  },
  async created() {

  },
  methods: {
    async jiazaiArr() {
      //如果id存在
      if (this.$route.query.id) {
        const response = await api.ajaxbaseUrlget('/api/Video/s?UserSelect=true&id=' + this.$decryptString(this.$route.query.id))
        //字符串转化json
        response.data.obj.data[0]['url'] = !this.$empty(response.data.obj.data[0].url)?JSON.parse(response.data.obj.data[0].url):[];
        //转字符串
        response.data.obj.data[0]['cat_id']= response.data.obj.data[0].cat_id.toString();
        this.ruleForm = response.data.obj.data[0];
      }
    }
    ,
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 30; // 10MB
      if (!isLt10M) {
        this.$message.error('File size must be less than 30MB!');
      }
      return isLt10M;
    },
    handleSuccess(response, file, fileList) {
      if (response && response.obj.data.url) {
        // const newFile = {
        //   name: file.name,
        //   url: response.obj.data.url,
        //   size: file.size,
        // };
        // this.ruleForm.url = this.ruleForm.url.concat(newFile).slice(-5);
      } else {
        this.$message.error('Invalid server response format');
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`The limit is 5 files. You selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    strReplace(v,replacements='{}') {
      setTimeout(() => {

        // 要进行替换的原始字符串
        var originalString = this.ruleForm[v];

        // 使用 replace() 函数进行多字符串替换
        for (var key in replacements) {
          if (replacements.hasOwnProperty(key)) {
            originalString = originalString.replace(new RegExp(key, "g"), replacements[key]);

          }
        }

        //判断英文第一个是否存在空格
        var jiancha=originalString.charAt(0);
        if(jiancha==' ') {
          originalString= originalString.replace(jiancha,'');
        }

        this.ruleForm[v]=originalString;

      }, 1000);

    },
    //封面设置
    uploadPicture() {
      this.cropperModel = true;
    },
    //图片上传成功后
    handleUploadSuccess(data) {

      this.ruleForm.img = data;

      this.cropperModel = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.ajaxbaseUrl.post('/api/Video/Au', this.ruleForm, {headers: {'Accept-Language': this.MY_CONSTANT.setApiLang}})
              .then(response => {

                this.$message.success({
                  message: response.data.msg,
                  offset: 400, duration: 3000
                });

              });
        } else {

          this.$nextTick(() => {
            // 获取错误节点
            let isError = this.$refs[formName].$el.getElementsByClassName('is-error')
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth',
            })
          })

          return false;
        }
      });

    },


  }
};
</script>

<style scoped>
/deep/ .quantity .el-input__wrapper {
  width: 50px;

}
</style>
