import axios from 'axios';
import {equipment, getCookie} from './globalFunctions.js';

const Api_CONSTANT = {
    baseUrl: process.env.NODE_ENV === 'development'
        ? '//api.cpse.com/' // 开发环境下的接口路径
        : '//api.cpse.com/',// 生产环境下的接口路径
    baseApiHeaders: {
        "Authorization": 'Bearer VUETXDKZVKXF7NZON91RHSDGOB0R39AK',
        "access-token": getCookie('token'),
        "Accept-Language": localStorage.getItem("Accept-Language") ?? 'zh-cn'
    },
    baseApiCache: {
        setCache: process.env.NODE_ENV === 'development' ? false : false, //是否开启Api接口缓存
        expires: 3600,//缓存时间

    }
}

async function SizeConfig() {
    return $cookies.isKey("SizeConfig") && Api_CONSTANT.baseApiCache.setCache
        ? JSON.parse(localStorage.getItem("SizeConfig"))
        : await axios.get(Api_CONSTANT.baseUrl + '/api/public_view/SizeConfig', {
            headers: Api_CONSTANT.baseApiHeaders
        }).then(response => {
            $cookies.set('SizeConfig', true, Api_CONSTANT.baseApiCache.expires);
            localStorage.setItem('SizeConfig', JSON.stringify(response.data.obj.data));
            return response.data.obj.data;
        });
}

async function menu() {
    //获取菜单
    return $cookies.isKey("menu") && Api_CONSTANT.baseApiCache.setCache
        ? JSON.parse(localStorage.getItem("menu"))
        : await axios.get(Api_CONSTANT.baseUrl + '/api/public_view/menu', {
            headers: Api_CONSTANT.baseApiHeaders
        }).then(response => {
            $cookies.set('menu', true, Api_CONSTANT.baseApiCache.expires);
            localStorage.setItem('menu', JSON.stringify(response.data.obj.data));
            return response.data.obj.data;
        });
}

async function DimensionLists() {
    return $cookies.isKey("DimensionLists") && Api_CONSTANT.baseApiCache.setCache
        ? JSON.parse(localStorage.getItem("DimensionLists"))
        : await axios.get(Api_CONSTANT.baseUrl + '/api/public_view/DimensionLists', {
            headers: Api_CONSTANT.baseApiHeaders
        }).then(response => {
            $cookies.set('DimensionLists', true, Api_CONSTANT.baseApiCache.expires);
            localStorage.setItem('DimensionLists', JSON.stringify(response.data.obj.data));
            return response.data.obj.data;
        });
}

async function AllApiConfig() {
    let data = await Promise.all([
        SizeConfig(),
        menu(),
        DimensionLists()]);
    return Object.assign({
        SizeConfig: data[0],
        menu: data[1],
        DimensionLists: data[2]
    }, Api_CONSTANT);
}

const lang = localStorage.getItem("Accept-Language") ? localStorage.getItem("Accept-Language") : 'zh-cn';
const Tytime = (new Date())
export const MY_CONSTANT = {
    Equipment:navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        ? 'mobile' : 'pc',
    Api_CONSTANT: await AllApiConfig(),
    CurrentYear: Tytime.getFullYear(),
    setApiLang: lang,
    apiLangArray:['zh-cn', 'en-us'],
    Lang: await import(`../../lang/${lang}.json`),
    sqlWhereLang: lang == 'zh-cn' ? 'cn' : 'en',//旧数据查询用到的
    // 设置API的基础URL
    User: {
        defaultLogo: 'https://cpsmedia.oss-cn-shenzhen.aliyuncs.com/upload/20230907/d525e2504936c91b6c74124ece6ed1c6.png',
    },
    Login: {
        expires: 86400,//登录过期时间
    },
    Upload: {
        logo: {
            size: 1,//M为单位
            width: 300,
            height: 300
        },
        video: {
            listCount: 3,//限制列表数量
            coverImgSize: 1,//M为单位
            coverImgWidth: 384,
            coverImgHeight: 216,
            videoSize: 30,//M为单位
            videoType: '.mp4,.flv'
        },
        product: {
            imgSize: 1,//M为单位
            imgWidth: 300,
            imgHeight: 300,
            informationSize: 10,//M为单位
            informationCount: 5,//限制数量
        },
    },
    StaticPath: process.env.NODE_ENV === 'development'
        ? '//admin.cpse.com/' // 开发环境下的静态资源路径
        : '//admin.cpse.com/', // 开发环境下的静态资源路径
    defaultNewLogo:'//admin.cpse.com/UploadFiles/Images/Article/1630482831330053010.png',
    // baseUrl:process.env.NODE_ENV === 'development'
    //   ? 'http://api.china-drone.com.cn/' // 开发环境下的接口路径
    //   : 'http://api.china-drone.com.cn/', // 生产环境下的接口路径
    // apiHeaders:{Authorization:'Bearer VUETXDKZVKXF7NZ0N91RHSDGOB0R39AK'},
    //查询条件默认
    getWhere: {
        op: '=',
        limit: '10',
        page: 1,
        order: '',
// {"add_time":"desc"}
        filter: '',
    },
//菜单
    //微信分享默认
    shareWx: {
        title: '安博会',
        imageUrl: 'https://usysresearch.com/html/img/logo2.png',
        description: '描述',

    },

};
// console.log(MY_CONSTANT);
